<main>
  <img src="./assets/logo.png" alt="logo" />
  <nav>
    <a href="//instagram.com/damas.ist">
      <img src="./assets/icons/instagram.svg" alt="instagram" />
    </a>
    <a href="//wa.me/00905380431753">
      <img src="./assets/icons/whatsapp.svg" alt="whatsapp" />
    </a>
    <a href="//goo.gl/maps/bBWyv9tFYFGw1pd4A">
      <img src="./assets/icons/google-maps.svg" alt="google maps" />
    </a>
  </nav>
</main>

<style>
  main {
		display: grid;
    place-content: center;
    justify-items: center;
    gap: 1.5rem;
    height: 100vh;
  }

  main > img {
    width: 100%;
    max-width: 28rem;
    padding: 0 2rem;
    transition: all 0.5s;
  }

  img:hover {
    transform: scale(1.1);
  }
</style>
